import useDocumentTitle from "../../useDocumentTitle";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { t } from "i18next";

const ViewLottery = () => {
  useDocumentTitle(`${t("lotteries.view-lottery")} | ${t("site-name")}`);
  const { ticket_id } = useParams();
  const [lottery, setLottery] = useState(null);
  const history = useHistory();
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const selectedLanguage = localStorage.getItem("i18nextLng");

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch(`/api/v1/userLotteries/${ticket_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            return response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((data) => {
            setLottery(data.ticket);
          });
      } catch (e) {
        if (e.message === "Unauthorized")
          window.location.href = `https://simbalotto.com${
            selectedLanguage === "en" ? "/login" : `/${selectedLanguage}/login`
          }`;
      }
    }
    fetchData();
  }, [history, selectedLanguage, ticket_id, token]);

  const lotteries = [
    { type: "megamillions", name: "Mega Millions", extra_1: "megaball" },
    { type: "powerball", name: "Powerball", extra_1: "powerball" },
    { type: "euromillions", name: "Euro Millions", extra_1: "stars" },
    { type: "eurojackpot", name: "Eurojackpot", extra_1: "euro" },
    { type: "el-gordo-primitiva", name: "El Gordo", extra_1: null },
    { type: "la-primitiva", name: "La Primitiva", extra_1: null },
    { type: "megasena", name: "Mega-Sena", extra_1: null },
    { type: "superenalotto", name: "SuperEnalotto", extra_1: null },
    { type: "lotto-america", name: "Lotto America", extra_1: "star" },
    { type: "lotto-6aus49", name: "Lotto 6aus49", extra_1: null },
    { type: "sat-lotto-au", name: "Saturday Lotto", extra_1: null },
    { type: "powerball-au", name: "Powerball AU", extra_1: "powerball" },
    { type: "lotto-fr", name: "French Lotto", extra_1: "chance" },
    { type: "lotto-uk", name: "UK Lottery", extra_1: null },
    { type: "lotto-ny-us", name: "New York Lotto", extra_1: null },
    { type: "thunderball", name: "Thunderball", extra_1: "thunderball" },
    { type: "set-for-life", name: "Set For Life", extra_1: "life" },
  ];

  function countNumbersInResults(numbers, results) {
    let count = 0;
    for (const number of numbers) {
      if (results?.includes(number)) {
        count++;
      }
    }
    return count;
  }

  function forceArray(number) {
    if (Array.isArray(number)) {
      return number;
    } else {
      return [number];
    }
  }

  return (
    <>
      {!lottery ? (
        <div className="container-fluid col-12 bg-simbalotto-green min-vh-100">
          <div className="row justify-content-center">
            <div id="preloader2"></div>
          </div>
        </div>
      ) : (
        <div className="row m-auto">
          <div className="col-12 mb-3">
            <div className="card shadow">
              <div className="card-header border-0"></div>
              <div className="card-body pt-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      {t("lotteries.fields.ticket-details")}
                    </h3>
                    <h6>
                      {t("lotteries.fields.purchase-date") +
                        ": " +
                        new Date(lottery?.purchased_at).toLocaleString("en-GB")}
                    </h6>
                    <h6>
                      {t("lotteries.fields.lottery") +
                        ": " +
                        lotteries.find((draw) => draw.type === lottery?.lottery)
                          ?.name}
                    </h6>
                    <h6 className="mt-1">
                      {t("lotteries.fields.ticket-lines") +
                        ": " +
                        lottery?.draws
                          ?.map((draw) => draw.numbers.length)
                          .reduce((acc, num) => acc + num, 0)}
                    </h6>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12">
                    {lottery &&
                      lottery?.draws?.map((ticket, index) => {
                        return (
                          <span className="px-3 mb-2" key={index}>
                            {ticket?.lottery && (
                              <div className="row">
                                <div className="col-xl-12">
                                  <h5>
                                    {t("lotteries.fields.lottery") +
                                      ": " +
                                      lotteries.find(
                                        (draw) => draw.type === ticket?.lottery
                                      )?.name}

                                    {ticket?.draw_date &&
                                      " - " +
                                        t("lotteries.fields.draw-date") +
                                        ": " +
                                        new Date(
                                          ticket?.draw_date
                                        ).toLocaleDateString()}
                                  </h5>
                                </div>
                              </div>
                            )}

                            {ticket?.draw_results &&
                              ticket.draw_results.numbers && (
                                <div className="row">
                                  <div className="col-12">
                                    <h5>
                                      {t("lotteries.fields.draw-results")}
                                    </h5>
                                  </div>
                                  <span className="mb-3 d-flex">
                                    {ticket?.draw_results?.numbers?.main
                                      ?.map((num) => num)
                                      .toString()
                                      .split(",")
                                      .map((number, index) => (
                                        <span
                                          key={index}
                                          className="bg-simbalotto-orange text-dark align-items-center d-flex justify-content-center mx-1"
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          {number}
                                        </span>
                                      ))}
                                    {lotteries.find(
                                      (draw) => draw.type === lottery?.lottery
                                    )?.extra_1 && (
                                      <span className="mx-4 d-flex">
                                        {forceArray(
                                          ticket?.draw_results?.numbers?.[
                                            lotteries.find(
                                              (draw) =>
                                                draw.type === lottery?.lottery
                                            )?.extra_1
                                          ]
                                        )?.map((num, index) => (
                                          <span
                                            key={index}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              lotteries.find(
                                                (draw) =>
                                                  draw.type === lottery?.lottery
                                              )?.extra_1
                                            }
                                            className="bg-simbalotto-orange text-dark align-items-center d-flex justify-content-center mx-1"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            {num}
                                          </span>
                                        ))}
                                      </span>
                                    )}
                                  </span>

                                  <span className="mb-3 align-items-center d-flex justify-content-center mx-1 text-capitalize">
                                    {t("lotteries.fields.draw-date") +
                                      ": " +
                                      new Date(
                                        ticket?.draw_results?.date
                                      )?.toLocaleDateString()}
                                  </span>

                                  <span className="mb-3 align-items-center d-flex justify-content-center mx-1 text-capitalize">
                                    {t("lotteries.fields.jackpot") +
                                      ": " +
                                      new Intl.NumberFormat("en", {
                                        style: "currency",
                                        currency:
                                          ticket?.draw_results?.currency,
                                      }).format(
                                        ticket?.draw_results?.jackpot?.total
                                      )}
                                  </span>
                                </div>
                              )}

                            {!ticket?.draw_results?.numbers && (
                              <div className="row">
                                <div className="col-xl-12">
                                  <h5 className="">
                                    {t("lotteries.fields.not-drawn")}
                                  </h5>
                                </div>
                              </div>
                            )}

                            {ticket.numbers.map((line, index) => (
                              <span className="row mb-2" key={index}>
                                {line.main?.split(",").map((num, index) => (
                                  <span
                                    key={index}
                                    className={
                                      (ticket?.draw_results?.numbers?.main
                                        ?.map((number) => number)
                                        .includes(Number(num))
                                        ? "bg-simbalotto-orange text-dark "
                                        : "bg-green-lottopawa text-white ") +
                                      "align-items-center d-flex justify-content-center mx-1"
                                    }
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {num}
                                  </span>
                                ))}

                                {lotteries.find(
                                  (draw) => draw.type === lottery?.lottery
                                )?.extra_1 && (
                                  <span className="mx-4 d-flex">
                                    {line.extra_1
                                      ?.split(",")
                                      .map((num, index) => (
                                        <span
                                          key={index}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            lotteries.find(
                                              (draw) =>
                                                draw.type === lottery?.lottery
                                            )?.extra_1
                                          }
                                          className={
                                            (Number(num) ===
                                            Number(
                                              forceArray(
                                                ticket?.draw_results?.numbers?.[
                                                  lotteries.find(
                                                    (draw) =>
                                                      draw.type ===
                                                      lottery?.lottery
                                                  )?.extra_1
                                                ]
                                              )[index]
                                            )
                                              ? "bg-simbalotto-orange text-dark "
                                              : "bg-green-lottopawa text-white ") +
                                            "align-items-center d-flex justify-content-center mx-1"
                                          }
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          {num}
                                        </span>
                                      ))}
                                  </span>
                                )}

                                <span>
                                  {t("lotteries.fields.ticket-match") + ": "}
                                  {`${countNumbersInResults(
                                    line?.main
                                      ?.split(",")
                                      .map((num) => Number(num)),
                                    ticket?.draw_results?.numbers?.main?.map(
                                      (num) => Number(num)
                                    )
                                  )}
                                  
                                  ${
                                    lotteries.find(
                                      (draw) => draw.type === lottery?.lottery
                                    )?.extra_1
                                      ? " + " +
                                        countNumbersInResults(
                                          line?.extra_1
                                            ?.split(",")
                                            .map((num) => Number(num)),
                                          forceArray(
                                            ticket?.draw_results?.numbers?.[
                                              lotteries.find(
                                                (draw) =>
                                                  draw.type === lottery?.lottery
                                              )?.extra_1
                                            ]
                                          )?.map((num) => Number(num))
                                        )
                                      : ""
                                  }
                                  `}

                                  {` / ${
                                    line?.main
                                      ?.split(",")
                                      .filter((item) => item).length
                                  }
                                  ${
                                    lotteries.find(
                                      (draw) => draw.type === lottery?.lottery
                                    )?.extra_1
                                      ? ` + ${
                                          line?.extra_1
                                            ?.split(",")
                                            .filter((item) => item).length
                                        }`
                                      : ""
                                  }`}
                                </span>

                                <span className="mx-3">
                                  {t("lotteries.fields.ticket-status") + ": "}
                                  <span
                                    className={
                                      line?.status === "won"
                                        ? " text-success"
                                        : line?.status === "lost"
                                        ? " text-danger"
                                        : " text-info"
                                    }
                                  >
                                    {line?.status}
                                  </span>
                                </span>

                                {line.status === "won" && line.win_amount && (
                                  <span className="mx-3">
                                    {t("lotteries.fields.ticket-prize") +
                                      ": " +
                                      line.win_amount?.toLocaleString("de-DE", {
                                        style: "currency",
                                        currency: "EUR",
                                        minimumFractionDigits: 2,
                                      })}
                                  </span>
                                )}
                              </span>
                            ))}
                          </span>
                        );
                      })}
                  </div>
                </div>

                <hr className="my-3" />
                <div className="row">
                  {lottery &&
                    lottery.image_scans &&
                    lottery.image_scans.map((image, index) => (
                      <div key={index} className="col-12 col-md-3 mb-3">
                        <img
                          src={image.image_scan}
                          alt="ticket"
                          className="img-fluid"
                        />
                      </div>
                    ))}
                </div>

                <hr className="my-3" />
                {lottery && (
                  <div className="row">
                    <div className="col-xl-12 mb-3">
                      {t("lotteries.fields.ticket-prize") + ": "}
                      {lottery.win_amount > 0 &&
                        lottery?.win_amount?.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                          minimumFractionDigits: 2,
                        })}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xl-12 mb-3">
                    <Link to="/my-tickets">
                      <button
                        className="btn btn-simbalotto-orange"
                        type="button"
                      >
                        {t("lotteries.fields.back-to-tickets")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewLottery;
