import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("i18nextLng");

  return (
    <footer className="footer px-4 bg-white m-auto">
      <div className="row align-items-center justify-content-md-between">
        <div className="col-12 col-md-3 mb-2">
          <div className="copyright text-center text-xl-left text-dark">
            © {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1 text-dark"
              href={`https://simbalotto.com${
                selectedLanguage === "en" ? "" : `/${selectedLanguage}`
              }`}
              rel="noopener noreferrer"
            >
              {t("footer.text-1")}
            </a>
          </div>
        </div>

        <div className="col-12 col-md-6 mb-2">
          <div className="text-center text-dark">
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                __html: t("footer.text-2", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mb-2 d-flex justify-content-center justify-content-md-end">
          <div className="row">
            <div className="col-6">
              <a
                href={`https://simbalotto.com${
                  selectedLanguage === "en" ? "" : `/${selectedLanguage}`
                }`}
                rel="noopener noreferrer"
                className="text-dark"
              >
                {t("footer.home")}
              </a>
            </div>
            <div className="col-6">
              <a
                href={`https://simbalotto.com${
                  selectedLanguage === "en" ? "" : `/${selectedLanguage}`
                }`}
                rel="noopener noreferrer"
                className="text-dark text-nowrap"
              >
                {t("footer.text-4")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
