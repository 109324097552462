import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { AppProvider } from "AppContext";
import * as Sentry from "@sentry/react";
import React from "react";
import "./i18n/config";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "assets/scss/simbalotto.scss";

import AdminLayout from "layouts/Admin.js";

const container = document.getElementById("root");
const root = createRoot(container);
const Login = require("views/Login/Login.js").default;

/**
 * Event listener for the 'message' event to receive and store the selected language in localStorage.
 *
 * This listener is intended to receive a message from the main domain (e.g., 'https://example.com')
 * containing the current language selected on the WordPress site using WPML. The message is sent via
 * the `postMessage` API from an iframe embedded in the main domain.
 *
 * Once the message is received and validated, the selected language is stored in the localStorage
 * under the key 'i18nextLng', which can then be used by React i18n for language localization.
 *
 * Parameters:
 *  - event: The message event that contains data sent from the main domain. This includes:
 *    - event.origin: The origin of the message sender, which should be verified to match the expected domain.
 *    - event.data: The payload of the message, expected to include a 'language' key with the language code.
 *
 * Actions:
 *  - Validates that the message is from the correct origin (main domain: 'https://example.com').
 *  - Checks if the message contains language data.
 *  - Stores the language code in localStorage under the key 'i18nextLng'.
 *  - Optionally, it can also trigger an immediate language change in React i18n by calling i18n.changeLanguage().
 *
 * Example usage:
 *  window.addEventListener('message', function(event) {
 *      if (event.origin === 'https://example.com' && event.data.language) {
 *          localStorage.setItem('i18nextLng', event.data.language);
 *          // i18n.changeLanguage(event.data.language); // Optional: Trigger language change immediately
 *      }
 *  });
 */

window.addEventListener("message", function (event) {
  // Ensure the message is coming from the correct origin
  if (event.origin === "https://simbalotto.com") {
    if (event.data.language) {
      localStorage.setItem("i18nextLng", event.data.language);
    }
  }
});

// Sentry.init({
//   dsn: "https://acda3fd7ff7e4b01b26dec640f39a8f3@o4504848175857664.ingest.sentry.io/4504848181690368",

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [new BrowserTracing(), new Sentry.Replay()],
//   tracesSampleRate: 1.0,
// });

root.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </BrowserRouter>
  </AppProvider>
);
