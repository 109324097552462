import i18n from "../../i18n/config";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useEffect } from "react";
import { useApp } from "../../AppContext";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from "@novu/notification-center";

const AdminNavbar = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { balance, novu, refetchBalance } = useApp();
  const location = useLocation();

  useEffect(() => {
    refetchBalance();
  }, [location, refetchBalance]);

  async function logout() {
    fetch("/cookies/clear-logout", {
      method: "GET",
    }).then((response) => {
      if (response.status === 200) {
        history.push("/login");
      }
    });
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
      flag: require("../../assets/img/flags/en.png"),
    },
    {
      code: "de",
      name: "German",
      country_code: "de",
      flag: require("../../assets/img/flags/de.png"),
    },
    {
      code: "es",
      name: "Spanish",
      country_code: "es",
      flag: require("../../assets/img/flags/es.png"),
    },
    {
      code: "fr",
      name: "French",
      country_code: "fr",
      flag: require("../../assets/img/flags/fr.png"),
    },
    {
      code: "it",
      name: "Italian",
      country_code: "it",
      flag: require("../../assets/img/flags/it.png"),
    },
    {
      code: "bg",
      name: "Bulgarian",
      country_code: "bg",
      flag: require("../../assets/img/flags/bg.png"),
    },
    {
      code: "da",
      name: "Danish",
      country_code: "dk",
      flag: require("../../assets/img/flags/da.png"),
    },
    {
      code: "fi",
      name: "Finnish",
      country_code: "fi",
      flag: require("../../assets/img/flags/fi.png"),
    },
    {
      code: "hu",
      name: "Hungarian",
      country_code: "hu",
      flag: require("../../assets/img/flags/hu.png"),
    },
    {
      code: "pl",
      name: "Polish",
      country_code: "pl",
      flag: require("../../assets/img/flags/pl.png"),
    },
    {
      code: "ro",
      name: "Romanian",
      country_code: "ro",
      flag: require("../../assets/img/flags/ro.png"),
    },
    {
      code: "sv",
      name: "Swedish",
      country_code: "sv",
      flag: require("../../assets/img/flags/sv.png"),
    },
    {
      code: "uk",
      name: "Ukrainian",
      country_code: "uk",
      flag: require("../../assets/img/flags/uk.png"),
    },
    {
      code: "cs",
      name: "Czech",
      country_code: "cz",
      flag: require("../../assets/img/flags/cz.png"),
    },
    {
      code: "el",
      name: "Greek",
      country_code: "gr",
      flag: require("../../assets/img/flags/gr.png"),
    },
    {
      code: "et",
      name: "Estonian",
      country_code: "et",
      flag: require("../../assets/img/flags/et.png"),
    },
    {
      code: "id",
      name: "Indonesian",
      country_code: "id",
      flag: require("../../assets/img/flags/id.png"),
    },
    {
      code: "ja",
      name: "Japanese",
      country_code: "jp",
      flag: require("../../assets/img/flags/jp.png"),
    },
    {
      code: "lt",
      name: "Lithuanian",
      country_code: "lt",
      flag: require("../../assets/img/flags/lt.png"),
    },
    {
      code: "lv",
      name: "Latvian",
      country_code: "lv",
      flag: require("../../assets/img/flags/lv.png"),
    },
    {
      code: "pt",
      name: "Portuguese",
      country_code: "pt",
      flag: require("../../assets/img/flags/pt.png"),
    },
    {
      code: "ru",
      name: "Russian",
      country_code: "ru",
      flag: require("../../assets/img/flags/ru.png"),
    },
    {
      code: "sk",
      name: "Slovak",
      country_code: "sk",
      flag: require("../../assets/img/flags/sk.png"),
    },
    {
      code: "tr",
      name: "Turkish",
      country_code: "tr",
      flag: require("../../assets/img/flags/tr.png"),
    },
    {
      code: "zh-CN",
      name: "Chinese (Simplified)",
      country_code: "zh",
      flag: require("../../assets/img/flags/zh.png"),
    },
    {
      code: "ko",
      name: "Korean",
      country_code: "ko",
      flag: require("../../assets/img/flags/ko.png"),
    },
    {
      code: "no",
      name: "Norwegian",
      country_code: "no",
      flag: require("../../assets/img/flags/no.png"),
    },
    {
      code: "mk",
      name: "Macedonian",
      country_code: "mk",
      flag: require("../../assets/img/flags/mk.png"),
    },
    {
      code: "sr",
      name: "Serbian",
      country_code: "sr",
      flag: require("../../assets/img/flags/sr.png"),
    },
    {
      code: "ee",
      name: "Estonian",
      country_code: "ee",
      flag: require("../../assets/img/flags/et.png"),
    },
    {
      code: "ar",
      name: "Arabic",
      country_code: "ar",
      flag: require("../../assets/img/flags/ar.png"),
    },
  ];

  return (
    <>
      <nav
        className="navbar-top navbar-dark navbar navbar-expand-md"
        id="navbar-main"
      >
        <div className="container-fluid">
          <Link
            className="h4 mb-0 text-dark text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>

          <ul className="align-items-center d-none d-md-flex navbar-nav">
            <div className="dropdown me-3">
              <i
                className=" fas fa-language fa-2x pr-2 text-simbalotto-green"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                role="button"
                id="dropdownLangMenu"
                data-toggle="dropdown"
              />

              <ul
                className="dropdown-menu dropdown-menu-start"
                aria-labelledby="dropdownLangMenu"
                style={{ minWidth: "25rem", marginLeft: "-20rem" }}
              >
                <div className="row">
                  {languages &&
                    languages.map((lang, index) => (
                      <li
                        key={index}
                        className="col-6"
                        onClick={() => {
                          changeLanguage(lang.code);
                        }}
                      >
                        <button
                          className="dropdown-item"
                          role="menuitem"
                          type="button"
                        >
                          <span>
                            <img src={lang.flag} alt={lang.name} />
                            <span className="ml-3">{lang.name}</span>
                          </span>
                        </button>
                      </li>
                    ))}
                </div>
              </ul>
            </div>

            {novu && (
              <li className="mr-2">
                <NovuProvider
                  backendUrl={novu.backendUrl}
                  socketUrl={novu.socketUrl}
                  applicationIdentifier={novu.APP_ID}
                  subscriberId={novu?.subscriberId}
                  subscriberHash={novu?.subscriberHash}
                  styles={{
                    footer: {
                      root: { display: "none" },
                    },
                  }}
                >
                  <PopoverNotificationCenter colorScheme={"light"}>
                    {({ unseenCount }) => (
                      <NotificationBell unseenCount={unseenCount} />
                    )}
                  </PopoverNotificationCenter>
                </NovuProvider>
              </li>
            )}

            <div className="dropdown">
              <div
                className="dropdown-toggle pr-0"
                role="button"
                id="dropdownMenuButton1"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="avatar avatar-sm rounded-circle bg-simbalotto-orange text-simbalotto-green">
                  <i className="fas fa-user" />
                </span>
                <span className="mb-0 text-sm text-dark font-weight-bold text-capitalize">
                  {t("menu.logged-in")}
                </span>
              </div>

              <ul
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    className="dropdown-item"
                    role="menuitem"
                    type="button"
                  >
                    <i className="fas fa-wallet" />
                    <span>
                      {balance.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </button>
                </li>
                <li>
                  <Link className="dropdown-item" to="/my-profile">
                    <i className="fas fa-user" />
                    <span>{t("menu.my-profile")}</span>
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => logout()}>
                    <i className="fas fa-person-running" />
                    <span>{t("menu.logout")}</span>
                  </button>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default AdminNavbar;
